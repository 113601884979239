<template>
  <div class="app-container">
    <!-- form表单 -->
    <el-form ref="form" :model="form" label-width="130px" style="display: flex">
      <div>
        <el-row type="flex" justify="space-between">
          <div style="width: 85%">
            <el-row class="Row-Input" :class="{ 'show-row': showRow }">
              <el-col :span="6">
                <el-form-item :label="$t('title.platforms')">
                  <el-select v-model="form.site" value-key="id" clearable filterable class="addWidth" @change="PlatformsiteChange">
                    <el-option
                      v-for="item in terraceData"
                      :key="item.id"
                      :label="item.siteCode"
                      :value="item.siteCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('title.SellingAccount')">
                  <el-select v-model="form.account" class="addWidth" value-key="id" clearable filterable @change="PlatformAccountChange">
                    <el-option :label="$t('title.All')" value="">{{ $t('title.All') }}</el-option>
                    <el-option
                      v-for="item in PlatformAccountList"
                      :key="item.id"
                      :label="item.accountName"
                      :value="item.accountName"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 工单状态 -->
              <el-col :span="6">
                <el-form-item :label="$t('title.TicketStatus')">
                  <el-select v-model="form.workStatus" class="addWidth" clearable filterable @change="workStatusChange">
                    <el-option value="0" :label="$t('title.Unfinished')" />
                    <el-option value="99" :label="$t('title.finished')" />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- BU -->
              <el-col :span="6">
                <el-form-item label="BU">
                  <el-select v-model="form.bu" class="addWidth" collapse-tags value-key="id" multiple filterable>
                    <el-option
                      v-for="item in buOptions"
                      :key="item.id"
                      :label="item.bu"
                      :value="item.bu"
                    />
                  </el-select>
                </el-form-item>
                <!-- <el-form-item label="BU">
                  <el-input v-model="form.bu" placeholder="请选择" />
                </el-form-item> -->
              </el-col>
              <!-- 客户id -->
              <el-col :span="6">
                <el-form-item :label="$t('title.customerID')">
                  <el-input v-model="form.messageSender" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>
              <!-- 标签 -->
              <el-col :span="6">
                <el-form-item :label="$t('title.ProblemMentioned')">
                  <el-cascader v-model="form.labelsId" class="addWidth" :options="tagData" clearable :props="props">
                    <template slot-scope="{ data }">
                      <span>{{ data.labelName }}</span>
                    </template>
                  </el-cascader>
                </el-form-item>
              </el-col>
              <!-- 平台订单号 -->
              <el-col :span="6">
                <el-form-item :label="$t('title.platNumber')">
                  <el-input v-model="form.orderCoder" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>
              <!-- 工单号 -->
              <el-col :span="6">
                <el-form-item :label="$t('title.Ticketno')">
                  <el-input v-model="form.workNumber" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>
              <!-- style -->
              <!-- <el-col :span="6">
                <el-form-item label="style">
                  <el-select v-model="form.style" value-key="id" clearable filterable>
                    <el-option
                      v-for="item in styleOptions"
                      :key="item.id"
                      :label="item.styleName"
                      :value="item.styleName"
                    />
                  </el-select>
                </el-form-item>
              </el-col> -->
              <!-- color -->
              <!-- <el-col :span="6">
                <el-form-item label="color">
                  <el-select v-model="form.color" clearable filterable>
                    <el-option
                      v-for="item in colorOptions"
                      :key="item.id"
                      :label="item.colorName"
                      :value="item.colorName"
                    />
                  </el-select>
                </el-form-item>
              </el-col> -->
              <!-- size -->
              <!-- <el-col :span="6">
                <el-form-item label="size">
                  <el-select v-model="form.size" clearable filterable>
                    <el-option v-for="item in sizeOptions" :key="item.id" :label="item.size" :value="item.size" />
                  </el-select>
                </el-form-item>
              </el-col> -->
              <!-- 产品等级 -->
              <el-col :span="6">
                <el-form-item :label="$t('title.Productrank')">
                  <el-select v-model="form.position" class="addWidth" :placeholder="$t('page.selectPlaceholder')" clearable>
                    <el-option value="P3" label="P3" />
                    <el-option value="P2" label="P2" />
                    <el-option value="P1" label="P1" />
                    <el-option value="P0" label="P0" />
                    <el-option value="新品" :label="$t('title.NewProduct')" />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 关键字 -->
              <el-col :span="6">
                <el-form-item :label="$t('title.keyword')">
                  <el-input v-model="form.messageKey" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>
              <!-- 平台sku -->
              <el-col :span="6">
                <el-form-item :label="$t('title.PlatformSku')">
                  <el-input v-model="form.sku" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>

              <el-col :span="6">
                <el-form-item :label="$t('title.shipmentnumber')">
                  <el-input v-model="form.trackingId" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('title.Finalrespondent')">
                  <el-select v-model="form.lastReplyName" class="addWidth" filterable>
                    <el-option
                      v-for="item in userOptions"
                      :key="item.id"
                      :label="item.userName"
                      :value="item.userName"
                    />
                  </el-select>
                  <!-- <el-input v-model="form.lastReplyName" :placeholder="$t('page.inputPlaceholder')" /> -->
                </el-form-item>
              </el-col>
              <!-- 消息id -->
              <!-- <el-col :span="6">
                <el-form-item label="消息ID">
                  <el-input v-model="form.messageId" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col> -->
              <el-col :span="10">
                <el-form-item :label="$t('title.time')">
                  <el-date-picker
                    v-model="timerange"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :range-separator="$t('title.to')"
                    :start-placeholder="$t('title.starttime')"
                    :end-placeholder="$t('title.endtime')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-row style="width: 15%">
            <el-col class="row-center">
              <el-button type="primary" :loading="tableLoading" @click="_getAmazonPagedList(0,true)"> {{ $t("page.search") }}</el-button>
              <el-button @click="resetQuery">{{ $t("page.reset") }}</el-button>
              <el-button type="text" @click="showRowClick()">{{ showRow ? $t("page.hide") : $t("page.expand") }}</el-button>
            </el-col>
          </el-row>
        </el-row>
        <el-row :span="24" style="margin: 5px 0">
          <el-col :span="24" style="display: flex; height: 36px">
            <!-- <el-button size="small" @click="handleBatchreply">{{ $t("title.Batchreply") }}</el-button> -->
            <!-- <el-button size="small" @click="getupdatemailstatusbylist('replied', 1)">{{ $t("title.Markasreplied") }} </el-button> -->
            <!-- <el-button size="small" @click="getupdatemailstatusbylist('read', 1)">{{ $t("title.Markasreaded") }} </el-button> -->
            <!-- <el-button size="small" @click="getupdatemailstatusbylist('read', 0)">{{ $t("title.Markasunread") }} </el-button> -->
            <el-button v-if="form.account" size="small" type="primary" @click="PullmailbyhanddialogVisible = true"> {{ $t("title.Manualsync") }}</el-button>
            <el-button type="primary" size="small" @click="handleDialogVisibleLable = true"> {{ $t("title.ProblemMentioned") }} </el-button>
            <!-- <el-button v-if="form.messageStatus == 1" type="primary" size="small" @click="noticeVisible = true">  提醒  </el-button> -->
            <el-button type="primary" size="small" @click="exportEmail"> {{ $t("page.export") }} </el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <table-select-action :quantity="multipleSelection.length" @reset="handleTableSelectReset" />
    <!-- tab切换 -->
    <el-tabs ref="tabs" v-model="form.messageStatus" @tab-click="handleTabsClick">
      <el-tab-pane v-for="item in StatusGroup" :key="item.messageStatus" :name="item.messageStatus + ''">
        <span slot="label">
          <span v-if="language === 'en-US'">{{ item.enremark }}</span> <span v-else>{{ item.messageStatusName }}</span>
          <span class="count ml-3" style="color: red">({{ item.messageCount }})</span></span>
        <BuyerEmailTable
          ref="table"
          :form="form"
          :table-data="tableData"
          :flag="true"
          :table-loading="tableLoading"
          :table-height="tableHeight"
          :disabled-flag="false"
          @getMul="getMul"
          @refresh="_getAmazonPagedList(0)"
          @getthreadList="getthreadList"
        />
      </el-tab-pane>
      <el-tab-pane :label="$t('title.Remindfollow')" name="12">
        <span slot="label">{{ $t("title.Remindfollow") }}<span class="count ml-3">({{ warnCount }})</span></span>
        <BuyerEmailTable
          ref="table"
          :form="form"
          :table-data="tableData"
          :flag="true"
          :table-loading="tableLoading"
          :table-height="tableHeight"
          :disabled-flag="false"
          @getMul="getMul"
          @refresh="_getAmazonPagedList(0)"
          @getthreadList="getthreadList"
        />
      </el-tab-pane>
    </el-tabs>
    <!-- 手工同步 -->
    <el-dialog :title="$t('title.Syncdate')" :visible.sync="PullmailbyhanddialogVisible" width="40%">
      <el-date-picker
        v-model="timeArr"
        type="datetimerange"
        value-format="yyyy-MM-dd HH:mm:ss"
        :range-separator="$t('title.to')"
        :start-placeholder="$t('title.starttime')"
        :end-placeholder="$t('title.endtime')"
        :picker-options="setDisabled"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="PullmailbyhanddialogVisible = false">{{ $t("title.cancel") }}</el-button>
        <el-button type="primary" :loading="pullByHandLoading" @click="PullmailbyhandSure">{{ $t("title.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 标签 -->
    <el-dialog :visible.sync="handleDialogVisibleLable" width="40%">
      <el-tree
        ref="tree"
        class="my-4 menu-true"
        highlight-current
        node-key="id"
        :expand-on-click-node="false"
        :data="lableDatas"
        :props="defaultProps"
        show-checkbox
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="tagSure">{{ $t("title.confirm") }}</el-button>
        <el-button size="small" @click="addLabel">{{ $t("title.NewManageLabe") }}</el-button>
      </span>
    </el-dialog>
    <!-- 新增管理标签 -->
    <el-dialog :title="$t('title.NewManageLabe')" :visible.sync="handleDialogVisiblewRemind" width="50%" @close="closeTag">
      <el-tree :data="lableDatas" node-key="id" default-expand-all :props="defaultProps" :expand-on-click-node="false">
        <el-row slot-scope="{ node, data }" class="custom-tree-node">
          <el-col>{{ node.label }}</el-col>
          <el-col>
            <el-button type="text" size="mini" @click="() => edit(data)">
              {{ $t("route.Added") }}
            </el-button>
            <el-button v-if="data.parentId === -1" type="text" size="mini" @click="() => append(2, data)">
              {{ $t("title.Addsecondarylabel") }}
            </el-button>
            <el-button v-else-if="node.level===2" type="text" size="mini" @click="() => append(3, data)">
              {{ $t("title.Addthreelevellabel") }}
            </el-button>
            <el-button type="text" size="mini" @click="() => remove(node, data)">
              {{ $t("page.delete") }}
            </el-button>
          </el-col>
        </el-row>
      </el-tree>
      <el-button v-if="show === 'button'" icon="el-icon-plus" style="width: 100%" class="mt-4" @click="show = 'input'">
        {{ $t("title.Addfirstlevellabel") }}</el-button>
      <el-row v-if="show === 'input'" :gutter="20" class="mt-3">
        <el-col :span="15">
          <el-input ref="labelInput" v-model="labelName" />
        </el-col>

        <el-col :span="3">
          <el-button @click="addSure(labelName, labelLevel, parentId, id)">{{
            $t("page.sure")
          }}</el-button>
        </el-col>
        <el-col :span="3">
          <el-button @click="addCancel">{{
            $t("title.cancel")
          }}</el-button>
        </el-col>
      </el-row>
      <div style="text-align: right; margin-top: 18px">
        <el-button @click="handleDialogVisiblewRemind = false">{{ $t("title.cancel") }}</el-button>
      </div>
    </el-dialog>
    <!-- 提醒 -->
    <!-- <el-dialog :visible.sync="noticeVisible" width="25%">
      <p style="display: flex; justify-content: space-between;cursor:pointer;" @click="handleRemind(1)">
        <span>明天 </span><span>中午12:00</span>
      </p>
      <p style="display: flex; justify-content: space-between;cursor:pointer;" @click="handleRemind(2)">
        <span>后天 </span><span>中午12:00</span>
      </p>
      <p style="display: flex; justify-content: space-between;cursor:pointer;" @click="handleRemind(3)">
        <span>三天内</span><span>中午12:00</span>
      </p>
      <p style="display: flex; justify-content: space-between;cursor:pointer;" @click="handleRemind(7)">
        <span>七天内</span><span>中午12:00</span>
      </p>
      <p @click="defineNoticeTimeVisible = true"><span>自定义</span></p>
      <p @click="handleNotice()">
        <span style="cursor:pointer;">查看 </span><span class="count">( {{ warnCount }} )</span>
      </p>
    </el-dialog> -->
    <!-- 自定义提醒时间设置 -->
    <!-- <el-dialog title="自定义日期" :visible.sync="defineNoticeTimedialogVisible" width="40%">
      <el-date-picker v-model="value1" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="defineNoticeTimedialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="defineNoticeTimeSure">确 定</el-button>
      </span>
    </el-dialog> -->
    <!-- 批量回复弹框 -->
    <!-- <el-dialog :title="'发送给 ' + handleBatchreplyList" :visible.sync="handleBatchreplydialogVisible" width="55%" @close="closeDialog">
      <el-row type="flex" justify="space-between">
        <el-col :span="10">
          <div style="position: relative">
            <el-input
              ref="replyInput"
              v-model="content"
              type="textarea"
              :placeholder="$t('title.EnterSend')"
              @keydown.enter.native="keyDown"
            />
            <i class="el-icon-s-custom faceicon" @click="faceContent" />
            <div v-if="faceShow" class="browBox">
              <ul>
                <li v-for="(item, index) in faceList" :key="index" @click="getBrow(index)">
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <div>
            <div style="height: 220px">
              <el-form :inline="true" class="demo-form-inline">
                <el-form-item :label="$t('title.classify')">
                  <el-select
                    v-model="type"
                    clearable
                    :placeholder="$t('page.selectPlaceholder')"
                    @change="_gettemplatelistbywhere"
                  >
                    <el-option :label="$t('title.OrderAll')" value="" />
                    <el-option v-for="item in typeData" :key="item.id" :label="item.typeName" :value="item.typeName" />
                  </el-select>
                </el-form-item>
              </el-form>
              <div style="height: 160px; overflow: auto">
                <el-row
                  v-for="(item, index) in typetableData"
                  :key="index"
                  type="flex"
                  justify="space-between"
                  class="border"
                >
                  <el-col :span="14" style="padding: 10px 0">
                    <el-tooltip class="item" effect="dark" :content="item.content" placement="top-start">
                      <span>{{ item.title }}</span></el-tooltip>
                  </el-col>
                  <el-col :span="6" :offset="6">
                    <el-button type="text" @click="copyText(item.content)">{{
                      $t("title.copy")
                    }}</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleBatchreplydialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handlesendmessageList">发 送</el-button>
      </span>
    </el-dialog> -->
    <el-pagination
      :current-page="pager.current"
      :page-sizes="[20, 50, 100, 200, 300]"
      :page-size="pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pager.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import {
  allBU,
  messagesDeleteLabel, messagesUpdateLabel, messagesSaveLabel,
  messagesGetLabel,
  querySiteList,
  getAmazonPagedList,
  getAmazonMessageStatus,
  updateAmazonMessageReadById,
  batchMarkLabelInsert,
  gettemplatelistbywhere,
  gettemplatetypelistbywhere,
  messagesListAllAccount,
  batchSendMessage,
  batchAmazonUpdateMessageStatus,
  selectMakeLabel,
  getmailormessageinfobyhand, getWarnMessageCount, batchUpdateMessageWarnById

} from '@/api/service-management'
import mixin from '@/mixin/oms-mixin'
// import { queryStyleList } from '@/api/omsorder'
import BuyerEmailTable from './components/BuyerEmailTable'
import TableSelectAction from '@/components/TableSelectAction'
import { deepClone } from '@/utils'
import Cookies from 'js-cookie'
// import store from '@/store'
const appData = require('@/views/service-manage/emoji.json')
export default {
  components: {
    TableSelectAction,
    BuyerEmailTable
  },
  mixins: [mixin],
  data() {
    return {
      language: '',
      props: { checkStrictly: true, value: 'id', label: 'labelName', children: 'childNodes' },
      pullByHandLoading: false,
      threadList: [],
      checkNotice: false,
      defineNoticeTimedialogVisible: false,
      warnCount: '',
      noticeVisible: false,
      value1: '',
      arrList: [],
      expandedKeys: [],
      id: '',
      sure: false,
      labelName: '',
      labelLevel: 1,
      parentId: -1,
      show: 'button',
      timeArr: [],
      definetimedialogVisible: false,
      PullmailbyhanddialogVisible: false,
      handleDialogVisibleLable: false,
      handleDialogVisiblewRemind: false,
      sendmessagelist: [],
      faceList: [],
      faceShow: false,
      typetableData: [],
      typeData: [],
      content: '',
      handleBatchreplydialogVisible: false,
      MessageStatus: '',
      StatusGroup: [],
      Allstatus: [],
      flag: true,
      tableHeight: '',
      type: '',
      tableLoading: true,
      tableData: [],
      lableDatas: [],
      treeConfig: {
        children: 'childNodes'
      },
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      dialogVisible: false,
      showRow: false,
      terraceData: [],
      PlatformAccountList: [],
      form: {
        site: 'US',
        account: [],
        platform: 'WALMART',
        messageType: '1',
        messageStatus: '0',
        labelsId: '',
        workStatus: '0',
        bu: '',
        workNumber: ''

      },
      labelsId: '',
      multipleSelection: [],
      basicInfo: {},
      noteList: [],
      timerange: [],
      messageIDList: [],
      handleBatchreplyList: [],
      index: '',
      defaultProps: {
        children: 'childNodes',
        label: 'labelName'
      },
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      tagData: [],
      buOptions: [],
      userOptions: [],
      isWarnLable: '',
      setDisabled: {
        disabledDate(time) {
          // return time.getTime() > Date.now() // 可选历史天、可选当前天、不可选未来天
          // return time.getTime() > Date.now() - 8.64e7;  // 可选历史天、不可选当前天、不可选未来天
          return time.getTime() < Date.now() - 8.64e7 // 不可选历史天、可选当前天、可选未来天
          // return time.getTime() < Date.now(); // 不可选历史天、不可选当前天、可选未来天
        }
      }

    }
  },
  computed: {
    getebaypagedlistParams() {
      this.getLabel()
      let isWarn = ''
      this.checkNotice ? isWarn = 1 : ''
      const [lastModifyTimeStart, lastModifyTimeEnd] = this.timerange || []
      const { workStatus, workNumber, site, account, messageId, orderCoder, messageSender, bu, FnSku, trackingId, lastReplyName, style, color, size, sku, position, messageKey, messageStatus } = this.form
      return Object.assign({}, { workStatus, workNumber, site: site ? site.toString() : '', account: account ? account.toString() : '', messageId, orderCoder, messageSender, bu: bu ? bu.join(',') : '', FnSku, trackingId, lastReplyName, style, color, size, sku, position, messageKey, messageStatus, platform: 'WALMART' }, this.pager, { lastModifyTimeStart, lastModifyTimeEnd, messageType: 1, isWarn, labelsId: this.labelsId }
      )
    },
    geteContlistParams() {
      this.getLabel()
      let isWarn = ''
      this.checkNotice ? isWarn = 1 : ''
      const [lastModifyTimeStart, lastModifyTimeEnd] = this.timerange || []
      const { workStatus, workNumber, site, account, messageId, orderCoder, messageSender, bu, FnSku, trackingId, lastReplyName, style, color, size, sku, position, messageKey, messageStatus } = this.form
      return Object.assign({}, { workStatus, workNumber, site: site ? site.toString() : '', account: account ? account.toString() : '', messageId, orderCoder, messageSender, bu: bu ? bu.join(',') : '', FnSku, trackingId, lastReplyName, style, color, size, sku, position, messageKey, messageStatus, platform: 'WALMART' }, { lastModifyTimeStart, lastModifyTimeEnd, messageType: 1, isWarn, labelsId: this.labelsId }
      )
    },
    getmessageallstatuscountbytypeParams() {
      let isWarn = ''
      this.checkNotice ? isWarn = 1 : ''
      const { workStatus, workNumber, site, account, messageId, orderCoder, messageSender, bu, FnSku, trackingId, lastReplyName, style, color, size, sku, position, messageKey } = this.form
      const [lastModifyTimeStart, lastModifyTimeEnd] = this.timerange || []
      return Object.assign({}, { lastModifyTimeStart, lastModifyTimeEnd, platform: 'WALMART' }, { workStatus, workNumber, site: site ? site.toString() : '', account: account ? account.toString() : '', messageId, orderCoder, messageSender, bu: bu ? bu.join(',') : '', FnSku, trackingId, lastReplyName, style, color, size, sku, position, messageKey }, { messageType: 1, isWarn, labelsId: this.labelsId }
      )
    },
    exportUrl() {
      this.getLabel()
      const obj = {}
      let isWarn = ''
      this.checkNotice ? isWarn = 1 : ''
      const { workStatus, workNumber, trackingId, isAttachment, site, emailId, lastReplyName, messageId, account, messageSender, orderCoder, bu, style, color, size, sku, position, messageKey, messageStatus } = this.form
      const [lastModifyTimeStart, lastModifyTimeEnd] = this.timerange || []
      const OBJ = Object.assign({}, { workStatus, workNumber, trackingId, isAttachment, site, emailId, lastReplyName, messageId, account: account ? account.toString() : '', messageSender, orderCoder, bu: bu ? bu.join(',') : '', style, color, size, sku, position, messageKey, labelsId: this.labelsId, messageStatus, messageType: 1 }, { isWarn, lastModifyTimeStart, lastModifyTimeEnd, platform: 'WALMART' })
      for (var key in OBJ) {
        OBJ[key] ? obj[key] = OBJ[key] : ''
      }
      console.log(obj)
      let url = ''
      for (var i in obj) {
        url = `${i}=${obj[i]}` + '&' + url
      }
      url = url.substr(0, url.length - 1)
      return process.env.VUE_APP_ERP_NEW_API + 'mos-web-core/Customer/exportMessageInfos?' + url
    }
  },
  created() {
    this.form.account.push('')
  },
  mounted() {
    // if (JSON.parse(sessionStorage.getItem('isflag'))) {
    const data = sessionStorage.getItem('queryForm')
    this.form = data ? JSON.parse(data) : this.form
    this.language = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
    this._getAmazonPagedList(0)//   this.handleQuery()
    // } else { this._getAmazonPagedList() }

    this._selectMakeLabel()
    this.getType()
    // this._gettemplatelistbywhere()
    this._messagesGetLabel()
    this._querySiteList()
    this.getmailaccountinfo('WALMART')
    this._allBU()
    // sessionStorage.removeItem('queryForm')
  },
  methods: {
    // 获取所有bu
    async _allBU() {
      const { datas } = await allBU()
      this.userOptions = datas
      // this.buOptions = datas
      this.buOptions = []
      datas.map(e => {
        if (e.userName === this.$store.getters.name && sessionStorage.getItem('queryForm') === null) {
          this.form.bu = e.bu.split(',')
        }
        const index = this.buOptions.findIndex((value) => value.bu === e.bu)
        index === -1 ? this.buOptions.push(e) : ''
      })
      sessionStorage.removeItem('queryForm')

      this._getAmazonPagedList(0)
    },
    closeDialog() {
      this.faceShow = false
      this.content = ''
    },
    closeTag() {
      this.show = 'button'
    },
    getLabel() {
      this.labelsId = this.form.labelsId
      this.labelsId = this.labelsId && this.labelsId[this.labelsId.length - 1]
    },
    async _selectMakeLabel() {
      const { datas } = await selectMakeLabel('WALMART', 1)
      this.tagData = datas
    },
    async tagSure() {
      if (!this.multipleSelection.length) {
        this.$message({
          type: 'warning',
          message: this.$t('tips.Selectleast')
        })
        return
      }
      const arr = []
      let obj = {}
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (this.multipleSelection[i].orderList.length) {
          for (var j = 0; j < this.multipleSelection[i].orderList.length; j++) {
            obj.messageId = this.multipleSelection[i].id
            obj.orderId = this.multipleSelection[i].orderList[j].id
            obj.labelId = this.$refs.tree.getHalfCheckedKeys().concat(this.$refs.tree.getCheckedKeys())
            arr.push(obj)
            obj = {}
          }
        } else {
          obj.messageId = this.multipleSelection[i].id
          obj.orderId = ''
          obj.labelId = this.$refs.tree.getHalfCheckedKeys().concat(this.$refs.tree.getCheckedKeys())
          arr.push(obj)
          obj = {}
        }
      }
      console.log(arr)
      const { code, msg } = await batchMarkLabelInsert(true, arr)
      code === 0 && this.$message.success(msg)
      this.handleDialogVisibleLable = false
      this._getAmazonPagedList(0)
    },
    getthreadList(val) {
      // console.log(val)
      this.threadList = val
    },
    getupdatemailstatusbylist(operate, status) {
      if (this.threadList.length === 0) {
        this.$message.error(this.$t('title.select'))
        return
      }
      let res = {}
      console.log(this.threadList)
      this.$confirm(this.$t('title.batchprocessing'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('title.confirm'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(async() => {
        if (operate === 'replied' && status === 1) {
          res = await batchAmazonUpdateMessageStatus(this.threadList, status)
        } else if (operate === 'read' && (status === 1 || status === 0)) {
          res = await updateAmazonMessageReadById(this.threadList, status)
        }
        res.code === 0 ? this.$message.success(this.$t('tips.Successful')) : this.$message.error(this.$t('tips.Faileds'))
        this._getAmazonPagedList(0)
      })
    },
    handleNotice() {
      this.checkNotice = true
      this.noticeVisible = false
      this._getAmazonPagedList(0)
    },
    async defineNoticeTimeSure() {
      const { code, msg } = await batchUpdateMessageWarnById(this.threadList, 1, this.value1)
      code === 0 && this.$message.success(msg)
      this.definetimedialogVisible = false
      this.value1 = ''
    },
    exportEmail() {
      window.open(this.exportUrl, '_blank')
    },
    async _getWarnMessageCount() {
      // delete this.pager.size
      // delete this.pager.current
      // delete this.pager.total
      // delete this.pager.pages
      // // delete this.getebaypagedlistParams.size
      // // delete this.getebaypagedlistParams.current
      // // delete this.getebaypagedlistParams.total
      // // delete this.getebaypagedlistParams.pages
      // this.getebaypagedlistParams.platform = 'WALMART'
      // this.getebaypagedlistParams.messageType = 1
      const { datas } = await getWarnMessageCount(this.geteContlistParams)
      this.warnCount = datas
    },
    async _getmailallstatuscountbytype() {
      const { datas } = await getAmazonMessageStatus(
        this.getmessageallstatuscountbytypeParams
      )
      for (var i = 0; i < datas.length; i++) {
        datas[i].messageStatus === 0
          ? (datas[i].enremark = 'Unanswered')
          : datas[i].messageStatus === 1
            ? (datas[i].enremark = 'Answered')
            : datas[i].messageStatus === 3
              ? (datas[i].enremark = 'Draft')
              : datas[i].messageStatus === 4
                ? (datas[i].enremark = 'Failed to sent')
                : ''
      }
      this.StatusGroup = datas
      this.noticeVisible = false
    },
    unique(arr) {
      return Array.from(new Set(arr))
    },
    handleCheckChange() { },
    // 获取style数据
    // async _queryStyleList() {
    //   const { datas } = await queryStyleList()
    //   this.styleOptions = datas
    // },
    // 选择style 带出color
    // styleSelectChange(value) {
    //   const data = this.styleOptions.find(item => item.styleName === value)
    //   this._queryColorList(data.id)
    //   this._querySizeList(data.id)
    // },
    // color信息获取
    // async _queryColorList(id) {
    //   const { datas } = await queryColorList({ styleId: id })
    //   this.colorOptions = datas
    // },
    // async _querySizeList(id) {
    //   const { datas } = await querySizeList({ styleId: id })
    //   this.sizeOptions = datas
    // },
    // 获取所有标签
    async _messagesGetLabel() {
      const { datas } = await messagesGetLabel()
      this.lableDatas = datas
      console.log(this.lableDatas)
    },
    handleNodeClick(data) {
      console.log(data)
    },
    // 新增
    insertEvent() {
      const xTree = this.$refs.xTree
      const newRow = {
        labelName: '一级标签',
        labelLevel: 1,
        parentId: -1
      }
      xTree.insert(newRow).then(({ row }) => xTree.setActiveRow(row))
    },
    async remove(node, data) {
      console.log(node, data)
      const { code } = await messagesDeleteLabel(data.id)
      code === 0 ? this.$message.success(this.$t('tips.Deleted')) : this.$message.error(this.$t('tips.faileds'))
      this.show = 'button'
      this._messagesGetLabel()
    },
    append(level, data) {
      this.show = 'input'
      this.parentId = data.id
      this.labelLevel = level
    },
    edit(data) {
      this.sure = true
      // console.log(node, data);
      this.show = 'input'
      this.labelName = data.labelName
      this.id = data.id
      console.log(this.$refs.labelInput)
    },
    addCancel() {
      this.show = 'button'
    },
    // 新增tag
    async addSure(labelName, labelLevel, parentId, id) {
      // console.log(labelName, labelLevel, parentId, id);
      if (this.sure) {
        const { code } = await messagesUpdateLabel(id, this.labelName)
        code === 0 ? this.$message.success(this.$t('tips.Changed')) : this.$message.error(this.$t('tips.failed'))
      } else {
        const { code } = await messagesSaveLabel({ labelName, labelLevel, parentId })
        code === 0 ? this.$message.success(this.$t('tips.Changed')) : this.$message.error(this.$t('tips.failed'))
      }
      this._messagesGetLabel()

      this.labelName = ''
      this.labelLevel = 1
      this.parentId = -1
      this.show = 'button'
      this.sure = false
    },
    resetQuery() {
      this.form = this.$options.data().form
      this.form.site = []
      this.timerange = []
      this.timeArr = []
      this.checkNotice = false
      if (this.form.workStatus === '99') {
        this.getebaypagedlistParams.messageStatus = ''
        this.$nextTick(function() {
          this.$refs.tabs.$children[0].$refs.tabs.map(e => {
            e.style.display = 'none'
          })
        })
      } else {
        this.$nextTick(function() {
          this.$refs.tabs.$children[0].$refs.tabs.map(e => {
            e.style.display = 'inline-block'
          })
        })
      }
      this._getAmazonPagedList(0)
    },
    getIndex(val) {
      this.index = val
    },
    async PullmailbyhandSure() {
      try {
        this.pullByHandLoading = true
        const [startTime, endTime] = this.timeArr || []
        const { account } = this.form
        const { status } = await getmailormessageinfobyhand('WALMART', account.toString() ? account : '-1', startTime, endTime)
        status === 200 ? this.$message.success(this.$t('tips.EmailRetrieved')) : this.$message.error(this.$t('tips.Failed'))
        this.pullByHandLoading = false

        this.PullmailbyhanddialogVisible = false
      } catch (err) {
        console.log(err)
      } finally {
        this.pullByHandLoading = false
      }
    },
    getBrow(index) {
      for (const i in this.faceList) {
        if (index + '' === i) {
          this.getBrowString = this.faceList[index]
          this.content += this.getBrowString
        }
      }
    },
    faceContent() {
      this.faceShow = !this.faceShow
      if (this.faceShow === true) {
        for (const i in appData) {
          this.faceList.push(appData[i].char)
        }
      } else {
        this.faceList = []
      }
    },
    copyText(value) {
      var input = document.createElement('input')
      input.value = value
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      this.$message.success(this.$t('tips.Copied'))
      this.$refs.replyInput.focus()
      this.content = value
    },
    async getType() {
      const { data } = await gettemplatetypelistbywhere({
        platform: 'WALMART'
      })
      this.typeData = data
    },
    async _gettemplatelistbywhere() {
      const { data } = await gettemplatelistbywhere({
        type: this.type,
        platform: 'WALMART'
      })
      this.typetableData = data
    },
    keyDown(e) {
      e = e || window.event
      e.ctrlKey && e.keyCode === 13 ? this.sendMsg() : ''
    },
    // 列表数据默认查询
    async _getAmazonPagedList(type, notice) {
      try {
        if (notice && notice === true) {
          this.checkNotice = false
        }
        if (this.isWarnLable === this.$t('title.Remindfollow')) {
          this.checkNotice = true
          this.getebaypagedlistParams.isWarn = 1
          this.getmessageallstatuscountbytypeParams.isWarn = 1
        } else {
          this.checkNotice = false
          this.getebaypagedlistParams.isWarn = ''
          this.getmessageallstatuscountbytypeParams.isWarn = ''
        }
        type === 0 ? this.pager.current = 1 : ''
        this.tableLoading = true
        const { datas: { pager, records }} = await getAmazonPagedList(this.getebaypagedlistParams)

        const arr = deepClone(records)
        const sku = []
        const style = []
        const bu = []
        let SKU = []
        let BU = []
        let STYLE = []
        for (var i = 0; i < arr.length; i++) {
          arr[i].orderList.map(item => {
            BU.push(item.bu)
            STYLE.push(item.style)
            SKU.push(item.sku)
          })
          sku.push(SKU)
          style.push(STYLE)
          bu.push(BU)
          SKU = []
          STYLE = []
          BU = []
        }
        // console.log(bu)
        for (var j = 0; j < sku.length; j++) {
          for (var k = 0; k < arr.length; k++) {
            arr[k].sku = this.unique(sku[k])
          }
        }
        for (let j = 0; j < style.length; j++) {
          for (let k = 0; k < arr.length; k++) {
            arr[k].style = this.unique(style[k])
          }
        }
        for (let j = 0; j < bu.length; j++) {
          for (let k = 0; k < arr.length; k++) {
            arr[k].bu = this.unique(bu[k])
          }
        }
        // console.log(arr)
        this.tableData = arr
        this.tableLoading = false
        this.tableData.forEach((ele, index) => {
          // 遍历ele对象 添加index属性
          ele.index = index
        })
        this.pager = pager
        this._getmailallstatuscountbytype()
        this._getWarnMessageCount()
        this._selectMakeLabel()
      } finally {
        this.tableLoading = false
      }
      // try {
      //   // type === 0 ? this.pager.PageCurrent = 1 : ''
      //   this.tableLoading = true
      //   const { datas: { pager, records }} = await getebaypagedlist(this.getebaypagedlistParams)
      //   // const { datas: { pager, records }} = res.data
      //   this.pager = pager
      //   // const arr = deepClone(item)
      //   this.tableData = records
      //   this.tableData.forEach((ele, index) => {
      //     ele.index = index
      //   })
      //   this._getmessageallstatuscountbytype()
      // } finally {
      //   this.tableLoading = false
      // }
    },

    //  列表分页方法
    handleSizeChange(val) {
      this.pager.size = val
      this._getAmazonPagedList(0)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._getAmazonPagedList(1)
    },
    // 子传父
    getMul(data) {
      this.multipleSelection = data
      this.messageIDList = this.multipleSelection.map((item) => item.messageSender)
      this.handleBatchreplyList = this.multipleSelection.map(
        (item) => item.messageSender
      )
    },
    PlatformsiteChange(val) {
      this.site = val
      this._getAmazonPagedList(this.getebaypagedlistParams)
    },
    PlatformAccountChange(val) {
      this.form.account = val
      this._getAmazonPagedList(this.getebaypagedlistParams)
    },
    workStatusChange(val) {
      this.getebaypagedlistParams.workStatus = val
      this.getebaypagedlistParams.messageStatus = ''
      this._getAmazonPagedList(this.getebaypagedlistParams)
      if (val === '99') {
        this.getebaypagedlistParams.messageStatus = ''
        this.$nextTick(function() {
          this.$refs.tabs.$children[0].$refs.tabs.map(e => {
            e.style.display = 'none'
          })
        })
      } else {
        this.getebaypagedlistParams.messageStatus = this.form.messageStatus
        this.$nextTick(function() {
          this.$refs.tabs.$children[0].$refs.tabs.map(e => {
            e.style.display = 'inline-block'
          })
        })
      }
    },
    showRowClick() {
      this.showRow = !this.showRow
    },
    async _querySiteList() {
      const { datas } = await querySiteList({ sellPlatformId: 8 })
      this.terraceData = datas
    },
    async getmailaccountinfo(platform) {
      const { datas } = await messagesListAllAccount(platform)
      this.PlatformAccountList = datas
    },
    handleTableSelectReset() {
      this.multipleSelection = []
      // console.log(this.multipleSelection)
      for (var i = 0; i < this.$refs.table.length; i++) {
        this.$refs.table[i].$refs.table.clearSelection()
      }
    },
    // 批量回复
    handleBatchreply() {
      if (this.messageIDList.length === 0) {
        this.$message.error(this.$t('title.select'))
        return
      }
      this.handleBatchreplydialogVisible = true
    },
    async handlesendmessageList() {
      this.content = this.content.replace(/<[^>]+>/g, '').trim()
      if (this.content === '') return this.$message.error(this.$t('tips.enterContent'))
      this.sendmessagelist = []
      for (var i = 0; i < this.multipleSelection.length; i++) {
        const { account, id, messageId, messageStatus, ebayMessageInfoList } = this.multipleSelection[i]
        const obj = Object.assign({}, {
          account, serviceMessageId: id, messageId, messageStatus,
          senderId: ebayMessageInfoList[0].recipientId, senderType: 2, subject: ebayMessageInfoList[0].subject, recipientId: ebayMessageInfoList[0].senderId
        }, { body: this.content.replace(/(\r\n)|(\n)/g, '<br>') })
        this.sendmessagelist.push(obj)
      }
      const { code } = await batchSendMessage(this.sendmessagelist)
      code === 200 && this.$message.success(this.$t('tips.Sent'))

      this.faceShow = false
      this.handleBatchreplydialogVisible = false
      this._getAmazonPagedList(0)
    },
    // 新增/管理标签
    addLabel() {
      this._messagesGetLabel()
      this.handleDialogVisiblewRemind = true
    },
    setDate(n, type) {
      var day3 = new Date()
      day3.setTime(day3.getTime() + n * 24 * 60 * 60 * 1000)
      var s3 = day3.getFullYear() + '-' + (day3.getMonth() + 1) + '-' + day3.getDate()
      if (type === 0) {
        return s3
      } else {
        return s3 + ' 12:00:00'
      }
    },
    async handleRemind(type) {
      if (this.threadList.length === 0) {
        this.$message.error(this.$t('title.select'))
        return
      }
      type && (type === 1 ? this.warnTime = this.setDate(1, 1) : type === 2 ? this.warnTime = this.setDate(2, 1) : type === 3 ? this.warnTime = this.setDate(3, 1)
        : type === 7 ? this.warnTime = this.setDate(7, 1) : '')
      console.log(this.warnTime)
      const { code, msg } = await batchUpdateMessageWarnById(this.threadList, 1, this.warnTime)
      code === 0 && this.$message.success(msg)
      this._getAmazonPagedList(0)

      this.noticeVisible = false
    },
    handleTabsClick(tab, event) {
      this.isWarnLable = tab.label
      console.log(this.isWarnLable)
      if (tab.label === this.$t('title.Remindfollow')) {
        this.getebaypagedlistParams.messageStatus = ''
        this.checkNotice = true
        this._getAmazonPagedList(this.getebaypagedlistParams)
      } else {
        this.getebaypagedlistParams.messageStatus = this.form.messageStatus
        this.getebaypagedlistParams.isWarn = ''
        this.getmessageallstatuscountbytypeParams.isWarn = ''
        this._getAmazonPagedList(this.getebaypagedlistParams)
        this._getmailallstatuscountbytype(this.getmessageallstatuscountbytypeParams)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.el-dialog__body {
    padding: 15px 10px;
    border: 1px solid #eee;
    margin: 5px 5px;
}
.browBox {
  position: absolute;
  bottom: -150px;
  width: 100%;
  height: 150px;
  background: #e6e6e6;
  overflow: scroll;
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 3px;
    li {
      width: 10%;
      font-size: 20px;
      list-style: none;
      text-align: center;
    }
  }
}
.tableTab {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #f4f4f5;
  span {
    padding: 0 50px;
  }
}
/deep/.el-textarea__inner {
  height: 150px;
  overflow: auto;
}
.faceicon {
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
}
/deep/.el-date-editor {
  width: 100%;
  .el-range-separator {
    padding: 0;
  }
}
.mark,
.count {
  color: red;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
/deep/.el-tabs__header {
  margin: 0 !important;
}
.Row-Input {
  height: 110px;
  overflow: hidden;
}
.show-row {
  height: auto;
}
.addWidth{
  width: 100%;
}
</style>
